<template>
    <div v-if="$can('agencia.menu.sucursal')">
        <a-row type="flex" :gutter="[2, 2]">
            <a-divider orientation="left">
				<span style="color:black; font-size:18px;">Sucursales</span> <span style="color:#9C9C9C; font-size:12px;">Visualiza la lista de sucursales</span>
			</a-divider>

            <a-col flex="1 1 100px">
				<a-button
                    v-if="$can('agencia.crear.nuevo.sucursal')"
                    type="primary"
                    block
                    @click="openModal(null, 'agregar')"
                    >
                    <a-icon type="plus" /> Nueva Sucursal
                </a-button>
            </a-col>
            
            <a-col flex="1 1 100px">
                
            </a-col>

            <a-col flex="1 1 200px">
				
            </a-col>

            <a-col flex="1 1 200px">
				
			</a-col>

            <a-col flex="1 1 400px">
                <a-input-search placeholder="Buscar sucursal en el sistema" @search="onSearch">
                    <a-button type="primary" slot="enterButton">
                        Buscar
                    </a-button>
                </a-input-search>
            </a-col>

            <a-col :span="1">
                <a-button @click="obtenerListadoActual">
                    <a-icon type="reload" />
                </a-button>
            </a-col>
        </a-row>

        <br>
               
        <a-table 
                :columns="columns" 
                :data-source="almacenes" 
                :pagination="paginate"
                :loading="loading"
                :ellipsis="true"
                size="small"
                :rowKey="record => record.almacen_id"
                :scroll="{ x: 980 }"
                @change="handlePagination"
                >

                <template slot="fechaCreacion" slot-scope="item">
                    {{ utils.formatDate(item.created_at) }}
                </template>

                <template slot="fechaActualizacion" slot-scope="item">
                    {{ utils.formatDate(item.updated_at) }}
                </template>

                <span slot="expandedRowRender" slot-scope="item" style="margin: 0">
                    <span style="font-weight:700; font-size:17px;">DATOS DE REGISTRO</span>
                    <a-row>
                        <a-col :span="12">
                            <a-list size="small" bordered><a-list-item>ID:</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>SUCURSAL:</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>ENCARGADO:</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>DIRECCION:</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>TELEFONO:</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>EMAIL:</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>HORARIO:</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>CIUDAD:</a-list-item></a-list>
                        </a-col>
                        <a-col :span="12">
                            <a-list size="small" bordered><a-list-item>{{ item.almacen_id }}</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>{{ item.nombre }}</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>{{ item.encargado }}</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>{{ item.direccion }}</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>{{ item.telefono }}</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>{{ item.email }}</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>{{ item.horario }}</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>{{ item.ciudad }}</a-list-item></a-list>
                        </a-col>
                    </a-row>
                </span>

                <template slot="action" slot-scope="item">
                    <a-dropdown>
                        <a-menu slot="overlay">
                            <a-menu-item @click.once="openModal(item, 'editar')" :key="buttonKeyEditar" v-if="$can('agencia.editar.sucursal')">
                                <a-tooltip placement="topLeft">
                                    <template slot="title">
                                        <span style="font-size:11px;">ID: {{ item.almacen_id }}</span>
                                    </template>
                                    <a-icon type="form" :style="{ fontSize: '18px', color: '#006ccc' }"/> <strong>Editar</strong>
                                </a-tooltip>
                            </a-menu-item>

                            <a-menu-item v-if="$can('agencia.eliminar.sucursal')">
                                <a-popconfirm
                                    title="¿Estás seguro(a) de eliminar este registro?"
                                    ok-text="Si"
                                    cancel-text="No"
                                    placement="topLeft"
                                    @confirm="eliminar(item.almacen_id)"
                                    >
                                    <a-tooltip>
                                        <template slot="title">
                                            <span>ID: {{ item.almacen_id }}</span>
                                        </template>
                                        <a-icon type="delete" :style="{ fontSize: '18px', color: '#cc2400' }"/> <strong>Eliminar</strong>
                                    </a-tooltip>
                                </a-popconfirm>
                            </a-menu-item>
                        </a-menu>
                        <a-button> Acciones <a-icon type="down" /> </a-button>
                    </a-dropdown>
                </template> 
        </a-table>
        
        <!-- MODAL AGREGAR EDITAR ALMACEN -->
        <a-modal
            v-model="modalObjeto"
            title="GESTION DE SUCURSALES"
            :closable="true"
            :destroyOnClose="false"
            :maskClosable="false"
            :width="420"
            :zIndex="1055"
            :dialog-style="{ top: '5px' }"
            >
    
            <div class="row mb-12">
    
                <div class="row">
                    <div class="col-md-12">
                        <label>Nombre Sucural: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                        <a-input size="large" v-model="form.nombre" placeholder="Nombre de la Sucursal" onfocus="myFunction(this)"/>
                    </div>
                </div>
    
                <div class="row">
                    <div class="col-md-12">
                        <label>Nombre Encargado Principal: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                        <a-input size="large" v-model="form.encargado" placeholder="Nombre del Encargado" onfocus="myFunction(this)"/>
                    </div>
                </div>
    
                <div class="row">
                    <div class="col-md-12">
                        <label>Dirección: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                        <a-input size="large" v-model="form.direccion" placeholder="Dirección" onfocus="myFunction(this)"/>
                    </div>
                </div>
    
                <div class="row">
                    <div class="col-md-12">
                        <label>Teléfono Celular: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                        <a-input size="large" v-model="form.telefono" placeholder="Teléfono" onfocus="myFunction(this)"/>
                    </div>
                </div>
    
                <div class="row">
                    <div class="col-md-12">
                        <label>Correo Electrónico: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                        <a-input size="large" v-model="form.correo" placeholder="Correo Electrónico" onfocus="myFunction(this)"/>
                    </div>
                </div>
    
                <div class="row">
                    <div class="col-md-12">
                        <label>Horario de Atención: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                        <a-input size="large" v-model="form.horario" placeholder="Horario de Atención" onfocus="myFunction(this)"/>
                    </div>
                </div>
    
                <div class="row">
                    <div class="col-md-12">
                        <label>Ciudad: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                        <a-input size="large" v-model="form.ciudad" placeholder="Ciudad" onfocus="myFunction(this)"/>
                    </div>
                </div>
            </div>
                    
            <template slot="footer">
                <a-button key="back" @click="modalObjeto = false">
                    Cancelar
                </a-button>
                <a-button @click="editar()" v-if="tipo === 'editar'" key="submit" type="inverse">
                    Actualizar
                </a-button>
                <a-button @click="agregar()" v-if="tipo === 'agregar'" key="submit" type="inverse">
                    Registrar
                </a-button>
            </template>
        </a-modal>
    
        <div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.5); z-index: 99999;">
            <div style="margin-top: 250px;">
                <vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Informacion..."></vue-simple-spinner>
                <br><br>
                <center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerloading = false"><i class="fa fa-stop"></i> Cerrar Ventana de Espera</button></center>
            </div>
        </div>
    
    </div>
</template>

<script>
    const columns = [
        { 
            id: 'almacen_id',
            title: 'Id', 
            dataIndex: 'almacen_id',
            key: 'almacen_id',
            width: '10%',
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
        { 
            id: 'almacen_id',
            title: 'Codigo', 
            dataIndex: 'codigo',
            key: 'codigo',
            width: '15%',
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
        { 
            id: 'almacen_id',
            title: 'Nombre', 
            dataIndex: 'nombre',
            key: 'nombre',
            width: '30%',
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
        { 
            id: 'almacen_id',
            title: 'F.Creacion', 
            dataIndex: '',
            key: 'created_at',
            scopedSlots: { customRender: 'fechaCreacion' },
            width: '20%',
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
        { 
            id: 'almacen_id',
            title: 'F.Actualizacion', 
            dataIndex: '',
            key: 'updated_at',
            scopedSlots: { customRender: 'fechaActualizacion' },
            width: '20%',
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
        { 
            id: 'almacen_id',
            title: 'Acciones', 
            dataIndex: '',
            scopedSlots: { customRender: 'action' },
            width: '15%'
        }
    ]

    import Vue from 'vue'
    import { mapGetters, mapActions } from 'vuex'
    import router from '@/config/router'
    import locale from 'ant-design-vue/es/date-picker/locale/es_ES'
    import moment from 'moment'
    import 'moment/locale/es'
    import * as utils from '@/utils/utils'
    import { EuropioCode } from '@/utils/europiocode'
    import axios from '@/config/axios/axios'
    import Swal from 'sweetalert2'
 
    export default {

        data () {
            return {
                dateFormat: 'YYYY-MM-DD',
                utils,
				moment,
				locale,
                columns,
                form: {
                    id: '',
                    nombre: '',
                    encargado: '',
                    direccion: '',
                    telefono: '',
                    correo: '',
                    horario: '',
                    ciudad: '',
                    password: ''
                },
                modalObjeto: false,
                tipo: '',
                lista: [],
                url: '',
                buttonKey: 1,
                showModal: false,
                spinnerloading: false,
                loading: false,
                buttonKeyActualizar: 10,
                buttonKeyEliminar: 20,
                buttonKeyEditar: 30,
                modalVerificarEliminar: false,
                paginate: {
                    pageSize: 10,
                    current: 1,
                    total: 0,
                    field: 'almacen_id',
                    order: 'descend'
                }
            }
        }, 

        computed: {
            ...mapGetters('almacen', ['almacenes'])
        },

        methods: {
            ...mapActions('almacen', ['createAlmacen', 'updateAlmacen', 'getAlmacenes', 'deleteAlmacen']),

            /** PAGINACION */
            handlePagination (paginate, filters, sorter) {
                this.paginate = {
                    pageSize: paginate.pageSize,
                    current: paginate.current,
                    total: paginate.total,
                    field: (sorter.field) ? sorter.field : 'almacen_id',
                    order: (sorter.order) ? sorter.order : 'descend'
                }
                this.paginarResultados()
            },

            changePaginate (data) {
                this.paginate = {
                    pageSize: data.per_page,
                    current: data.current_page,
                    total: data.total,
                    field: 'almacen_id',
                    order: 'descend'
                }
            },

            paginarResultados () {
                this.getAlmacenes(this.paginate)
                .then(response => {
                    this.changePaginate(response.data.meta)
                }).catch(error => {
                    this.loading = false
                    utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
                })
            },

            onSearch (value) {
            },

            obtenerListadoActual (value) {
                this.paginarResultados()
            },

            /** MODAL */
            openModal (data, tipo) {
                this.tipo = tipo
                if (tipo === 'editar') {
                    this.form.id = data.almacen_id
                    this.form.nombre = data.nombre
                    this.form.encargado = data.encargado
                    this.form.direccion = data.direccion
                    this.form.telefono = data.telefono
                    this.form.correo = data.email
                    this.form.horario = data.horario
                    this.form.ciudad = data.ciudad

                    this.buttonKeyEditar++
                } else {
                    this.form.nombre = ''
                    this.form.encargado = ''
                    this.form.direccion = ''
                    this.form.telefono = ''
                    this.form.correo = ''
                    this.form.horario = ''
                    this.form.ciudad = ''
                }
                this.modalObjeto = true
                this.buttonKeyActualizar++
            },

            agregar () {
                this.spinnerloading = true
				this.createAlmacen(this.form)
                .then(response => {
                    if (response.status === 201) {
                        this.paginarResultados()
					    utils.openNotificationWithIcon('success', 'Exito', response.data.message, 'topRight')
                        this.spinnerloading = false
                        this.modalObjeto = false
                    }
				})
                .catch(error => {
					this.loading = false
					utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
				})
                this.buttonKeyActualizar++
            },

            editar () {
                this.spinnerloading = true
				this.updateAlmacen(this.form)
                .then(response => {
                    if (response.status === 201) {
                        this.paginarResultados()
					    utils.openNotificationWithIcon('success', 'Exito', response.data.message, 'topRight')
                        this.spinnerloading = false
                        this.modalObjeto = false
                    }
				})
                .catch(error => {
					this.loading = false
					utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
				})
                this.buttonKeyActualizar++
            },

            eliminar (id) {
                this.spinnerloading = true
				this.deleteAlmacen(id)
                .then(response => {
                    if (response.status === 201) {
                        this.paginarResultados()
					    utils.openNotificationWithIcon('success', 'Exito', response.data, 'topRight')
                        this.spinnerloading = false
                    }
				}).catch(error => {
					this.loading = false
					utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
				})
                this.buttonKeyEliminar++
			}
        },

        mounted () {
            this.paginarResultados()
        }
    }
</script>